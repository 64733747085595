import React from 'react';
import frame29 from '../Assets/Frame 29.png';

function Map() {
	return (
		<div className="flex justify-center">
			<img src={frame29} alt="Cartography" className="w-full h-auto" />
		</div>
	);
}

export default Map;
